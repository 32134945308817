import React, { Component } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default class Portfolio extends Component {
  render() {
    return (
      <Layout>
        Sample Projects
        <Link to="/">Some where</Link>
      </Layout>
    )
  }
}
